@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.home{
margin-top: 5rem;
background-color: #F9F4F6;
}

.hamberg-menu{
  display: none;
}

.container-stats{
  background-color: white;
  padding: 15px;
  margin: 5rem 10rem; 
  border-radius: 50px;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2);
}
.stat-desc{
  font-size: 15px !important;
}

.services{
  background-color:#fff;
  margin-top: 5rem;
  padding: 6rem 0;
  overflow-x: hidden;
  
}
.heading{
  margin-bottom: 1rem !important;  
}

.sub-heading{
  font-weight: 500!important;
}

.card{
  margin: 10px 1px;
  background-color: #FFF;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.10);
  padding:  1rem !important;
}
.experince-area{
  background-color: #FFF3EF;
  padding: 2rem 0 !important;
  overflow-x: hidden;

}
.experince-list{
  margin: 1rem ;
  padding: 0rem 5rem;

}

.work-area{
  padding:  2rem 0;
  background-color: #ffffff;
  overflow-x: hidden;

}
.work-header{
  margin-bottom: 2rem;
}
.reviews{
  padding:  2rem 0;
  background-color: #F0F0F0;
  overflow-x: hidden;

}
.review-card{
  margin: 10px 1px;
  padding: 1.5rem 2rem !important;
  background-color: #F8F8F8;
  border-radius: 10px;;
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
}
.rating{
  padding: 0.5rem 0;
}

.reviewButton{
  margin-top: 2rem;
}

.blogs{
  padding:  2rem 0rem 4rem 0;
  background-color: #FFF3EF;
  overflow-x: hidden;
}
.blog-heading2{
  display: none;
}
.blog-title{
  margin: 2rem 0;
}

.blog-card{
  margin: 10px 1px;
  padding: 1.5rem 2rem !important;
  background-color: #fff;
  border-radius: 10px;;
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
}

.blog-date{
  font-size: 12px;
}
.contact{
  background-color: #fff;
  overflow-x: hidden;
  padding: 2rem 5rem !important;
}

.footer{
  text-align: center;
  background-color: #FFF3EF;
}

@media screen and (max-width: 768px) {
  .container-stats {
    position: static;
    left: auto;
    top: auto;
    transform: none;  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    border-radius: 0;
    margin: 0;
    margin-top: 2rem;
    padding: 1rem;
    /* box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2); */
  }

  .nav-items{
    display: none;
  }
  .blog-heading{
    display: none;
  }
  .blog-heading2{
    display: block;
  }
  .experince-list{
    margin: 1rem ;
    padding: 0rem;
  
  }
  
  .contact{
    overflow-x: hidden;
    padding: 1rem  !important;
  }

}

@media (min-width: 768px) and (max-width: 959px) {
  .container-stats {
    position: static;
    left: auto;
    top: auto;
    transform: none;  
    margin: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 2rem;
    border-radius: 0;
    margin-top: 2rem;
    padding: auto;
    width: 100%;
    /* box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2); */
  }

  .experince-list{
    margin: 1rem ;
    padding: 2rem;
  
  }

 


}